﻿// Function to emphasize headlines, convert "**" to <strong>, and handle "br" and "br/"
function emphasizeHeadlines() {
    // Retrieve the value of the CSS custom property --emphasize-headline
    const emphasizeClass = getComputedStyle(document.documentElement).getPropertyValue('--emphasize-headline').trim();

    // Select all headings (h1 through h5)
    const headings = document.querySelectorAll('h1, h2, h3, h4, h5');

    headings.forEach(heading => {
        // Replace "br" or "br/" with <br> tags
        heading.innerHTML = heading.innerHTML.replace(/\bbr\/?\b/g, '<br>');

        // Find words wrapped in "**" and replace with <strong>, ensuring content is not empty
        heading.innerHTML = heading.innerHTML.replace(/\*\*(.+?)\*\*/g, (match, p1) => {
            return `<strong>${p1.trim()}</strong>`;
        });

        // Find words wrapped in "*" (single stars) and replace with a span, ensuring content is not empty
        heading.innerHTML = heading.innerHTML.replace(/\*(.+?)\*/g, (match, p1) => {
            return `<span class="${emphasizeClass}">${p1.trim()}</span>`;
        });
    });
}

// Call the function after DOM is loaded
document.addEventListener('DOMContentLoaded', emphasizeHeadlines);
