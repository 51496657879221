﻿import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

document.addEventListener("DOMContentLoaded", function () {
    const netflixSlider = document.querySelector(".netflix-slider");
    const netflixSlideCount = netflixSlider ? netflixSlider.querySelectorAll('.swiper-slide').length : 0;

    const netflixSwiper = new Swiper(".netflix-slider", {
        slidesPerView: netflixSlideCount > 1 ? 1.10 : 1,
        watchSlidesProgress: true,
        spaceBetween: 16,
        breakpoints: {
            640: { spaceBetween: 24, slidesPerView: 2 },
            992: { spaceBetween: 24, slidesPerView: 2 },
            1280: { spaceBetween: 24, slidesPerView: 3 },
        },
        navigation: {
            nextEl: '.ph-caret-right',
            prevEl: '.ph-caret-left',
        },
    });
});
document.addEventListener("DOMContentLoaded", function () {

    const tabSlider = new Swiper(".tab-slider", {
        slidesPerView: 'auto', // Automatically adjust based on available space
        watchSlidesProgress: true,
        centeredSlides: false,
        navigation: {
            nextEl: '.ph-caret-right',
            prevEl: '.ph-caret-left',
        },
    });
});
document.addEventListener("DOMContentLoaded", function () {

    const logoSlider = new Swiper(".logo-slider", {
        slidesPerView: 1.20,
        watchSlidesProgress: true,
        spaceBetween: 8,
        centeredSlides: false,
        breakpoints: {
            640: { slidesPerView: 2 },
            992: { slidesPerView: 3 },
            1280: { slidesPerView: 4 },
        },
        navigation: {
            nextEl: '.ph-caret-right',
            prevEl: '.ph-caret-left',
        },
    });
});
document.addEventListener("DOMContentLoaded", function () {
    // Select all slideshow elements
    const slideshows = document.querySelectorAll(".slideshow");

    // Loop through each slideshow and initialize Swiper independently
    slideshows.forEach((slideshow, index) => {
        const slideshowSlideCount = slideshow.querySelectorAll('.swiper-slide').length;

        // Add a unique class or data attribute to distinguish each instance
        slideshow.dataset.swiperId = `swiper-${index}`;

        // Initialize Swiper
        const slideshowSwiper = new Swiper(slideshow, {
            slidesPerView: 1,
            loop: slideshowSlideCount > 1, // Enable loop only if there are enough slides
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: `.swiper-button-next`, // Ensure correct buttons are used
            },
            on: {
                init: function () {
                    updateSlideCounter(this, index);
                },
                slideChange: function () {
                    updateSlideCounter(this, index);
                }
            }
        });
    });

    // Update the slide counter for a specific Swiper instance
    function updateSlideCounter(swiper, index) {
        const currentIndex = swiper.realIndex + 1;
        const slideshow = document.querySelector(`[data-swiper-id="swiper-${index}"]`);
        const counter = slideshow.querySelector('.swiper-counter');
        if (counter) {
            counter.textContent = currentIndex;
        }
    }
});


document.addEventListener("DOMContentLoaded", function () {
    const autoSlider = document.querySelector(".auto");
    const autoSlideCount = autoSlider ? autoSlider.querySelectorAll('.swiper-slide').length : 0;

    const autoSwiper = new Swiper(".auto", {
        slidesPerView: 1,
        loop: autoSlideCount > 1, // Enable loop only if there are enough slides
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
        }
    });
});

document.addEventListener("DOMContentLoaded", function () {
    const timelineSlider = document.querySelector(".timeline-slider");
    const timelineSlideCount = timelineSlider ? timelineSlider.querySelectorAll('.swiper-slide').length : 0;

    const timelineSwiper = new Swiper(".timeline-slider", {
        slidesPerView: timelineSlideCount > 1 ? 1.20 : 1,
        watchSlidesProgress: true,
        centeredSlides: true,
        spaceBetween: 36,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });
});